import React from 'react';
import './contact.css';
import { Navbar, Footer } from '../'

const Contact = () => {
    return (
        <div className="App">
            <Navbar currentPage={"Contact"} />
            <div className="AppContent">
                <div className='Contact'>
                    <div className='transparent_content_panel'>
                        <p className='content_title'>Contact</p>
                        <p className='content_text_centered'>General inquiries: <a href='mailto:contact.kaval@gmail.com' target="_blank" rel="noreferrer">contact.kaval@gmail.com</a></p>
                        <br />
                        <p className='content_text_centered'>Bookings (Europe): <a href='mailto:contact.kaval@gmail.com' target="_blank" rel="noreferrer">sjoerd@mbartists.co.uk</a></p>
                        <br />
                        <p className='content_text_centered'>Demos: <a href='mailto:contact.kaval@gmail.com' target="_blank" rel="noreferrer">demos@kavaltunes.com</a></p>
                        <br />
                        <p className='content_text_centered'>I'm always available on Discord if you have any more general questions, you can join my server <a href='https://discord.com/invite/RBSBuej' target="_blank" rel="noreferrer">here</a>!</p>
                        <br />
                        <p className='content_text_centered'><a href='Kaval_EPK.pdf' target="_blank" rel="noreferrer">Press Kit</a></p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Contact;
