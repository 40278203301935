import React, { useState, useEffect } from 'react';
import './music.css';
import axios from 'axios';
import querystring from "querystring";
import { Navbar, Footer } from '../'

const client_id = "03ccbf9beb44464ba4d53874c9daff17";
const client_secret = "7bd0ac168f634590a195a93e44498620";

const nb_releases = 3;

const ReleaseCard = (props) => (
    <>
        <div className='release_card'>
            <a href={props.releaseLinkAll} target="_blank" rel="noreferrer" className="release_card_link">
                <img src={props.releaseArt} alt={props.releaseName} className="release_card_art" />
                <p className='release_card_info'>
                    {props.releaseName}
                </p>
            </a>
        </div>
    </>
)

function Music() {
    const [data, setData] = useState([]);

    const getAuth = async () => {
        const response = await axios.post(
            'https://accounts.spotify.com/api/token',
            querystring.stringify({
                grant_type: 'client_credentials',
            }),
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                auth: {
                    username: client_id,
                    password: client_secret,
                }
            }
        );
        return response.data.access_token;
    };

    const getArtist = async () => {
        const access_token = await getAuth();

        const api_url = `https://api.spotify.com/v1/artists/3R2J0IILBHpBvmcaW4cBci`;
        try {
            const response = await axios.get(api_url, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            });
            return response.data;
        } catch (error) {
            console.log(error);
        }
    };

    const getArtistAlbums = async () => {
        const access_token = await getAuth();

        const api_url = `https://api.spotify.com/v1/artists/3R2J0IILBHpBvmcaW4cBci/albums`;
        try {
            const response = await axios.get(api_url, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            });
            return response.data;
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        async function fetchData() {
            const artist = await getArtistAlbums();
            var new_items = [];
            for (var i = 0; i < nb_releases; i++) {
                new_items.push(artist.items[i]);
            }
            setData(new_items);
        }
        fetchData();
    }, []);

    return (
        <div className="App">
            <Navbar currentPage={"Music"} />
            <div className="AppContent">
                <div className='Music'>
                    <div className='music_panel'>
                        <div className='transparent_content_panel'>
                            <p className='content_title'>Discography</p>
                            <div className='padding_10pt'/>
                            <div className='discography' height={20 * (nb_releases - 1) + 200 * nb_releases}>
                                <iframe className='playlist_iframe' src="https://open.spotify.com/embed/playlist/3Nl2NYSCJYC2WkqouX6M3H?utm_source=generator&theme=0" title="Kaval Discography" width="100%" height={20 * (nb_releases - 1) + 200 * nb_releases} frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                            </div>
                        </div>
                    </div>

                    <div className='music_panel'>
                        <div className='transparent_content_panel'>

                            <p className='content_title'>Latest releases</p>
                            <div className='padding_10pt'/>
                            {
                                data.map(function (item, i) {
                                    return (
                                        <>
                                            <ReleaseCard releaseName={item.name} releaseArt={item.images[0].url} releaseLinkSP={item.uri} releaseLinkSC={item.uri} releaseLinkYT={item.uri} releaseLinkAll={item.uri} />
                                            { i < nb_releases - 1 && <div className='padding_20px'/> }
                                        </>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
export default Music;
