
import { React, useEffect } from 'react';
import './shows.css';
import kaval_show_photo from '../../img/LAUNCH-PARTY-92.jpg'
import { Navbar, Footer } from '../'

const useScript = url => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = url;
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, [url]);
};


const Shows = () => {
    return (
        <div className="App">
            <Navbar currentPage={"Shows"} />
            <div className="AppContent">
                <div className='Shows'>
                    <div className='shows_left_panel'>
                        <img src={kaval_show_photo} alt="Kaval" width="100%" height="900" />
                    </div>
                    <div className='shows_right_panel'>
                        <div className='transparent_content_panel'>
                            <p className='content_title'>Upcoming shows</p>

                            <a href="https://www.songkick.com/artists/8492888" class="songkick-widget" data-theme="dark" data-track-button="on" data-background-color="transparent" data-locale="en">Upcoming Kaval shows</a>
                            {useScript('//widget.songkick.com/8492888/widget.js')}

                            <p className='content_title'>Book Kaval</p>

                            <p className='content_text'>Bookings (Europe): <a href='mailto:contact.kaval@gmail.com' target="_blank" rel="noreferrer">sjoerd@mbartists.co.uk</a></p>

                        </div>
                    </div>
                    <div className='shows_panel'>
                        <div className='transparent_content_panel'>

                            <p className='content_title'>Mixes</p>

                            <iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1553352205%3Fsecret_token%3Ds-bG38ilnE3GI&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Shows;