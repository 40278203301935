import React from 'react';
import './footer.css';

import logo_spotify from '../../img/socials/spotify.png'
import logo_soundcloud from '../../img/socials/soundcloud.png'
import logo_youtube from '../../img/socials/youtube.png'
import logo_x from '../../img/socials/x.png'
import logo_instagram from '../../img/socials/instagram.png'
import logo_facebook from '../../img/socials/facebook.png'
import logo_discord from '../../img/socials/discord.png'
import logo_audius from '../../img/socials/audius.png'

const Footer = () => {
    return (
        <footer className='footer'>
            <a className="footer_item" href="https://spoti.fi/37JbbWg" target="_blank" rel="noreferrer"><img src={logo_spotify} alt="Spotify"/></a>
            <a className="footer_item" href="https://soundcloud.com/kavaltunes" target="_blank" rel="noreferrer"><img src={logo_soundcloud} alt="Soundcloud"/></a>
            <a className="footer_item" href="https://youtube.com/c/kavaltunes" target="_blank" rel="noreferrer"><img src={logo_youtube} alt="Youtube"/></a>
            <a className="footer_item" href="https://x.com/kavaltunes" target="_blank" rel="noreferrer"><img src={logo_x} alt="X"/></a>
            <a className="footer_item" href="https://instagram.com/kavaltunes" target="_blank" rel="noreferrer"><img src={logo_instagram} alt="Instagram"/></a>
            <a className="footer_item" href="https://facebook.com/kavaltunes" target="_blank" rel="noreferrer"><img src={logo_facebook} alt="Facebook"/></a>
            <a className="footer_item" href="https://discord.com/invite/RBSBuej" target="_blank" rel="noreferrer"><img src={logo_discord} alt="Discord"/></a>
            <a className="footer_item" href="https://audius.co/kaval" target="_blank" rel="noreferrer"><img src={logo_audius} alt="Audius"/></a>
        </footer>
    );
  }
  
  export default Footer;