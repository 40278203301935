import { React, useState } from 'react';
import './navbar.css';
import logo from '../../img/kaval_logo_2023.svg'
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri'
import { Link } from "react-router-dom";

const MenuItem = (props) => (
  <>
    <button className={`${props.currentPage === props.itemName ? "navbar_button_active" : ""}`} onClick={() => {props.setCurrentPage(props.itemName); props.setToggleMenu(false)}}>
      {props.itemName}
    </button>
  </>
)

const Navbar = (props) => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [currentPage, setCurrentPage] = useState(props.currentPage)
    return (
      <div className='navbar'>
        <div className='navbar_content'>
          <Link key={"home"} to={`/`}><MenuItem itemName="Home" setCurrentPage={setCurrentPage} currentPage={currentPage} /></Link>
          <Link key={"music"} to={`/music`}><MenuItem itemName="Music" setCurrentPage={setCurrentPage} currentPage={currentPage} /></Link>
          <div className='navbar_logo'><img src={logo} alt="Kaval" /></div>
          <Link key={"shows"} to={`/shows`}><MenuItem itemName="Shows" setCurrentPage={setCurrentPage} currentPage={currentPage} /></Link>
          <Link key={"contact"} to={`/contact`}><MenuItem itemName="Contact" setCurrentPage={setCurrentPage} currentPage={currentPage} /></Link>
        </div>
        <div className='navbar_mobile'>
          <div className='navbar_mobile_logo'>
            <img src={logo} alt="Kaval"/>
          </div>
          <div className='navbar_menu'>
            { toggleMenu ? 
              <RiCloseLine color="fff" size={27} onClick={() => setToggleMenu(false)} /> : 
              <RiMenu3Line color="fff" size={27} onClick={() => setToggleMenu(true)} />
            }
            { toggleMenu && (
              <div className="navbar_menu_container scale-up-center">
                <div className="navbar_menu_container_item scale-up-center">
                  <Link key={"home"} to={`/`}><MenuItem itemName="Home" setCurrentPage={setCurrentPage} currentPage={currentPage} setToggleMenu={setToggleMenu} /></Link>
                  <Link key={"music"} to={`/music`}><MenuItem itemName="Music" setCurrentPage={setCurrentPage} currentPage={currentPage} setToggleMenu={setToggleMenu} /></Link>
                  <Link key={"shows"} to={`/shows`}><MenuItem itemName="Shows" setCurrentPage={setCurrentPage} currentPage={currentPage} setToggleMenu={setToggleMenu} /></Link>
                  <Link key={"contact"} to={`/contact`}><MenuItem itemName="Contact" setCurrentPage={setCurrentPage} currentPage={currentPage} setToggleMenu={setToggleMenu} /></Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  
  export default Navbar;
  