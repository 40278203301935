import './App.css';
import { HashRouter, Route, Routes } from "react-router-dom";

import { Home, Music, Shows, Contact } from './components'

const App = () => {
  return (
    <HashRouter>
      <Routes>
        <Route index element = { <Home /> }/>
        <Route path="/music" element = { <Music /> }/>
        <Route path="/shows" element = { <Shows /> }/>
        <Route path="/contact" element = { <Contact /> }/>
      </Routes>
    </HashRouter>
  );
}

export default App;
