import React from 'react';
import './home.css';
import kaval_portrait_photo from '../../img/Kaval Portrait Dark 3.png'
import { Navbar, Footer } from '../'

const Home = () => {
    return (
        <div className="App">
            <Navbar currentPage={"Home"} />
            <div className="AppContent">
                <div className='Home'>
                    <div className='home_left_panel'>
                        <img src={kaval_portrait_photo} alt="Kaval" width="100%" height="600" />
                    </div>
                    <div className='home_right_panel'>
                        <div className='transparent_content_panel'>
                            <p className='content_title'>About Kaval</p>

                            <p class="content_text">
                                Kaval is a bass music producer from France.
                                His unique melodic and heavy approach to the dubstep genre has earned him support from artists such as <a href="https://open.spotify.com/artist/4zymhxzJ0UJc74tMHGyoWs?si=jkeUVeIsQKe5yy5k2imVZQ" target="_blank" rel="noreferrer">Dirtyphonics</a>, <a href="https://open.spotify.com/artist/06VibSJEr3GLxLBBZhRums?si=ZHmUDDh2QO-enXD5KOYlMA" target="_blank" rel="noreferrer">Dion Timmer</a>, <a href="https://open.spotify.com/artist/3bwENxqj9nhaAI3fsAwmv9?si=e2fb8f11400545c1" target="_blank" rel="noreferrer">Tokyo Machine</a>, <a href="https://open.spotify.com/artist/3hMTYaexWgGkXqvbkt6EIS?si=arRg38TzSQ-vYrQpQRSwiQ" target="_blank" rel="noreferrer">Chime</a>, <a href="https://open.spotify.com/artist/043Bey7ngSGRFpJftAlKpi?si=Hu05r-A3TyeTrW4ywLFZRA" target="_blank" rel="noreferrer">Spag Heddy</a> and many more. <br />
                            </p>
                            <p class="content_text">
                                Valentin has always had a passion for music, from his days of playing the trumpet and the trombone in his town's orchestra when he was younger, to making mashups as a teenager, followed by music production since 2015, and DJing for his university's parties later on.
                                His orchestra and music theory experience combined with his love for dubstep and melodic bass music has helped him develop his ever-evolving signature style. <br />
                            </p>
                            <p class="content_text">
                                The Kaval project was started in 2016, and has been growing ever since.
                                Throughout the years, Kaval has been very consistent, releasing 5 EPs and many singles either by himself or on labels such as <a href="https://soundcloud.com/disciple" target="_blank" rel="noreferrer">Disciple</a>, <a href="https://soundcloud.com/heavensentrecs" target="_blank" rel="noreferrer">Heaven Sent</a>, <a href="https://soundcloud.com/rushdownrecs" target="_blank" rel="noreferrer">Rushdown</a>, <a href="https://soundcloud.com/exobolt" target="_blank" rel="noreferrer">Most Addictive</a>, <a href="https://soundcloud.com/mostaddictivemusic" target="_blank" rel="noreferrer">Exobolt</a>, and <a href="https://soundcloud.com/dubstepfrance" target="_blank" rel="noreferrer">DubstepFrance</a>.
                                He has also collaborated with artists such as <a href="https://open.spotify.com/artist/5UCxi2O9gdyXj7fNgQE9xR?si=3P7cNhjLQLuKhohcLqZfng" target="_blank" rel="noreferrer">Dr. Ushūu</a>, <a href="https://open.spotify.com/artist/4lnwcv2fzQL7Y7bguVBvQ9?si=qbQkpd5VS76TchxilhSDiA" target="_blank" rel="noreferrer">The Brig</a>, <a href="https://open.spotify.com/artist/2858y2JiMYIst8dY4WXGi3?si=gQTzUWJtRAGB7WSwsXtbGw" target="_blank" rel="noreferrer">Skybreak</a> and <a href="https://open.spotify.com/artist/2lGnBQgzDURIqGR3Mg6S3z?si=GyXT9g1sRDmDz4D3yUb0aA" target="_blank" rel="noreferrer">AKVMA</a>, and released official remixes for <a href="https://open.spotify.com/artist/043Bey7ngSGRFpJftAlKpi?si=Hu05r-A3TyeTrW4ywLFZRA" target="_blank" rel="noreferrer">Spag Heddy</a>, <a href="https://open.spotify.com/artist/6G1en7nfPSAWElhPgUOy0h?si=A4xj4AeAT2yM5bb6PI6GEw" target="_blank" rel="noreferrer">NAZAAR</a>, <a href="https://open.spotify.com/artist/3hMTYaexWgGkXqvbkt6EIS?si=arRg38TzSQ-vYrQpQRSwiQ" target="_blank" rel="noreferrer">Chime</a>, <a href="https://open.spotify.com/artist/5o2KBzYUFierWmBhSemAhq?si=PXZoo4pMTfqcFMBqnmUhsA" target="_blank" rel="noreferrer">Ace Aura</a> & <a href="https://open.spotify.com/artist/2QelCpyRKtvplFEFLcLUZz?si=QtqVlPDbSrOsaQs3S1jqZQ" target="_blank" rel="noreferrer">Millenial Trash</a> and more. <br />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Home;